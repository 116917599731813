// ** Initial State
const initialState = {
  allData: [],
  customerData: [],
  data: [],
  params: {},
  NotesData: []
}

const customerManagement = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_DATA":
      return { ...state, allData: action.data }
    case "GET_CUSTOMER_DATA":
      return { ...state, customerData: action.data }
    case "GET_DATA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case "GET_NOTES":
      return { ...state, NotesData: action.getNote }
    case "ADD_USER":
      return { ...state }
    case "DELETE_NOTES":
      return { ...state, deleteNote: action.data }
    default:
      return { ...state }
  }
}
export default customerManagement
