const initialState = {
  businessDetails: []
}

const businessData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BUSINESS_DATA":
      return {
        ...state,
        businessDetails: action.data
      }
    case "POST_BUSINESS_DATA":
      return { ...state, businessDetailsPostRes: action.data }
    case "PUT_BUSINESS_DATA":
      return { ...state, businessDetailsPutRes: action.putBusinessData }
    default:
      return { ...state }
  }
}
export default businessData
