const initialState = {
  getEnqueries: [],
  getAuthOtp: [],
  ManualEnquiries: [],
  createCustomer: []
}

const getEnqueries = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_DATA_ENQUERIES":
      return {
        ...state,
        getEnqueries: action.data
      }
    case "GET_AUTH_OTP":
      return { ...state, getAuthOtp: action.data }
    case "CREATE_CUSTOMERS":
      return { ...state, createCustomer: action.data }
    case "MANUAL_ENQUIRIES":
      return { ...state, ManualEnquiries: action.data }
    default:
      return { ...state }
  }
}
export default getEnqueries
