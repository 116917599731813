// **  Initial State
const initialState = {
  userData: JSON.parse(localStorage.getItem("userData")) ?? {},
  accessToken: localStorage.getItem("accessToken") ?? "",
  refreshToken: localStorage.getItem("refreshToken") ?? "",
  resetPassword: []
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: action.data,
        [action.config.storageTokenKeyName]:
          action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]:
          action[action.config.storageRefreshTokenKeyName]
      }
    case "LOGOUT":
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }
    case "RESET_PASSWORD":
      return { ...state, resetPassword: action.data }
    default:
      return state
  }
}

export default authReducer
