// ** Initial State
const initialState = {
  users: [],
  usersLength: [],
  selectedUser: {},
  userDetails: {}
}

const getUsers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS":
      return { ...state, users: action.data }
    case "GET_USERS_LENGTH":
      return { ...state, usersLength: action.data }
    case "ADD_USERS":
      return { ...state, createUser: action.data }
    case "EDIT_USERS":
      return { ...state, selectedUser: action }
    case "GET_USER_DETAILS":
      return { ...state, userDetails: action }
    default:
      return { ...state }
  }
}
export default getUsers
