// ** Redux Imports
import { combineReducers } from "redux"

// ** Reducers Imports
import auth from "./auth"
import navbar from "./navbar"
import layout from "./layout"
import archive from "./archive"
import settings from "./settings"
import mediaObject from "./mediaObject"
import newsList from "./backoffice/news"
import pageList from "./backoffice/pages"
import listing from "./backoffice/listing"
import getUsers from "./backoffice/users"
import enqueries from "./backoffice/enqueries"
import contactUs from "./backoffice/contactUs"
import smsCampaignList from "./backoffice/smsCampaigns"
import emailCampaignList from "./backoffice/emailCampaigns"
import businessAccount from "./backoffice/businessAccount"
import customerManagement from "./backoffice/customerManagement"

// const rootReducer = combineReducers({
//   auth,
//   archive,
//   layout,
//   navbar,
//   listing,
//   newsList,
//   pageList,
//   enqueries,
//   mediaObject,
//   businessAccount,
//   customerManagement
// })

const appReducer = combineReducers({
  auth,
  archive,
  layout,
  navbar,
  listing,
  getUsers,
  newsList,
  settings,
  contactUs,
  pageList,
  enqueries,
  mediaObject,
  smsCampaignList,
  emailCampaignList,
  businessAccount,
  customerManagement
})

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action)
    // const { routing } = state
    // state = { routing }
  }

  return appReducer(state, action)
}

export default rootReducer
