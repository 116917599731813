const initialState = {
  getListData: [],
  getFilterListing: [],
  getBusinessCategory: [],
  getListingsDetail: {},
  getEnquiriesCount: []
}

const getListings = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LISTINGS":
      return {
        ...state,
        getListData: action.data
      }
    case "GET_FILTER_LISTINGS":
      return { ...state, getFilterListing: action.data }
    case "GET_BUSINESS_CATEGORIES":
      return {
        ...state,
        getBusinessCategory: action.data
      }
    case "ADD_NEW_LISTINGS":
      return { ...state }
    case "GET_LISTINGS_DETAIL":
      return { ...state, getListingsDetail: action.data }
    case "GET_ENQUIRIES_COUNT":
      return { ...state, getEnquiriesCount: action.data }
    default:
      return { ...state }
  }
}
export default getListings
