const initialState = {
  emailCampaignsList: [],
  getCampaignDetails: [],
  getCustomersEmail: []
}

const emailCampaignsData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EMAIL_CAMPAIGNS_LIST":
      return {
        ...state,
        emailCampaignsList: action.data
      }
    case "CREATE_EMAIL_CAMPAIGN":
      return { ...state }
    case "GET_EMAIL_CAMPAIGN":
      return {
        ...state,
        getCampaignDetails: action.data
      }
    case "GET_CUSTOMERS_EMAIL":
      return {
        ...state,
        getCustomersEmail: action.data
      }
    default:
      return { ...state }
  }
}
export default emailCampaignsData
