const initialState = {
  getArchiveListing: [],
  getArchiveCustomersList: [],
  getArchiveNews: [],
  getArchivePages: []
}

const archiveData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ARCHIVE_LISTINGS":
      return {
        ...state,
        getArchiveListing: action.data
      }
    case "GET_ARCHIVE_CUSTOMER":
      return { ...state, getArchiveCustomersList: action.data}
    case "GET_ARCHIVE_NEWS":
      return {
        ...state,
        getArchiveNews: action.data
      }
    case "GET_ARCHIVE_PAGES":
      return {
        ...state,
        getArchivePages: action.data
      }
    default:
      return { ...state }
  }
}
export default archiveData
