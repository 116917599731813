const initialState = {
    newsList: [],
    getNewsDetails: []
}

const newsData = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_NEWS_LIST':
            return {
                ...state,
                newsList: action.data
            }
        case 'ADD_NEWS':
            return { ...state }
        case 'GET_NEWS':
            return { 
                ...state, 
                getNewsDetails: action.getNewsDetails 
            }
        default:
            return { ...state }
    }
}
export default newsData