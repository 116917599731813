const initialState = {
    pageList: [],
    getPagesDetails: []
}

const pageData = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PAGE_LIST':
            return {
                ...state,
                pageList: action.data
            }
        case 'ADD_NEW_PAGE':
            return { ...state }
        case 'GET_PAGES':
            return { 
                ...state, 
                getPagesDetails: action.getPagesDetails 
            }
        default:
            return { ...state }
    }
}
export default pageData