const initialState = {
    imgPath: []
}

const mediaObject = (state = initialState, action) => {
    switch (action.type) {
        case 'IMAGE_UPLOAD':
            return {
                ...state,
                imageData: action.imageData
            }
        default:
            return { ...state }
    }
}
export default mediaObject