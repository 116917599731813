const initialState = {
  data: [],
  password: {},
  forgotPassword: []
}

const getFooterData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FOOTER_DETAIL":
      return {
        ...state,
        data: action.data
      }
    case "CHANGE_PASSWORD":
      return {
        ...state,
        password: action.data
      }
    case "FORGOT_PASSWORD":
      return {
        ...state,
        forgotPassword: action.data
      }
    default:
      return { ...state }
  }
}
export default getFooterData
