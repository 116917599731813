// ** React Imports
import { Suspense, lazy } from "react"
import ReactDOM from "react-dom"

// ** Sentry Imports
// import * as Sentry from "@sentry/react"
// import { BrowserTracing } from "@sentry/tracing"

// ** Redux Imports
import { Provider } from "react-redux"
import { store } from "./redux/storeConfig/store"

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify"
import { ThemeContext } from "./utility/context/ThemeColors"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

// ** Service Worker
import * as serviceWorker from "./serviceWorker"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY,
//   integrations: [new BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0
// })

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <ThemeContext>
        {/* <Sentry.ErrorBoundary fallback={<Error /> }> */}
        <LazyApp />
        <ToastContainer newestOnTop />
        {/* </Sentry.ErrorBoundary> */}
      </ThemeContext>
    </Suspense>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
