// ** Logo
import logo from '@src/assets/images/logo/watkins.png'
// ** Reactstrap
import { PulseLoader } from 'react-spinners'

const styles = {
  loading: { 
    position: 'relative',
    padding: '11px'
  }
}

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100'>
      <div className='fallback-logo'>
        <img className='rounded' src={logo} alt='logo' width="80" />
        <div style={styles.loading} >
          <PulseLoader color='#37052e' size={15} />
        </div>
      </div>
    </div>
  )
}

export default SpinnerComponent
