const initialState = {
  smsCampaignsList: [],
  getCampaignDetails: []
}

const smsCampaignsData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SMS_CAMPAIGNS_LIST":
      return {
        ...state,
        smsCampaignsList: action.data
      }
    case "CREATE_SMS_CAMPAIGN":
      return { ...state }
    case "GET_SMS_CAMPAIGN":
      return {
        ...state,
        getCampaignDetails: action.getCampaignDetails
      }
    default:
      return { ...state }
  }
}
export default smsCampaignsData
