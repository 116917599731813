const count = localStorage.getItem("counts")

const initialState = {
  getAllContactMessage: [],
  getContactMessage: [],
  counter: count
}

const getContactMessage = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CONTACT":
      return {
        ...state,
        getAllContactMessage: action.data
      }
    case "GET_CONTACT_MESSAGE":
      return {
        ...state,
        getContactMessage: action.data
      }
    case "UNREAD_ENQUIRIES_MESSAGE":
      return { ...state, counter: action.data }
    default:
      return { ...state }
  }
}
export default getContactMessage
